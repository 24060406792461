export default {
    methods: {
        navigateLogin() {
            this.$store.commit("clearState")
            this.$router.push({ name: "Login" }, () => {})
        },
        
        navigateStartseite() {
            this.$router.push({ name: "Startseite" }, () => {})
        },
        navigatePostbox() {
            this.$router.push({ name: "Postbox" }, () => {})
        },
        navigateContact() {
            this.$router.push({ name: "Contact" }, () => {})
        },

        async navigatePruefung() {
            const roles = await this.$store.dispatch("getUserRole")

            if (roles.includes("ROLE_BOOT")) {
                this.$router.push({ name: "Wasserfahrzeug" }, () => {})
            } else if (roles.includes("ROLE_FLUGZEUG")) {
                this.$router.push({ name: "Luftfahrzeug" }, () => {})
            } else {
                console.log("Nutzer hat keine passende Rolle! -> " + roles)
            }
        }
    }
 }