<template>
  <div class="sis-main">
    <v-form ref="form" @keyup.enter.native="sendLogin()" v-model="valid" lazy-validation>
      <v-alert dense type="error" v-if="error">
        {{ $t('login.error') }}
      </v-alert>
      <v-alert dense type="error" v-if="blocked">
        {{ $t('login.blocked') }}
      </v-alert>
      <Card xs="6" md="3" class="sis-push-center">
        <v-list-item-title class="sis-login-header">
          <h2>{{ $t('login.title') }}</h2>
        </v-list-item-title>
        <v-card-text>
          <v-container>
            <v-row>
              <Inputfield sm="12" md="12" v-model="login.username" :label="$t('login.username')" :required=true>
                  <i class="fas fa-user sis-font-size-25"></i>
              </Inputfield>

              <Inputfield sm="12" md="12" v-model="login.password" :label="$t('login.password')" type="password" :required=true>
                  <i class="fas fa-lock sis-font-size-25"></i>
              </Inputfield>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="sis-login-button" :disabled="!valid" @click="sendLogin()">
            {{ $t('login.button') }}
          </v-btn>
        </v-card-actions>
      </Card>
    </v-form>
  </div>
</template>

<script>
import Inputfield from '@/components/Inputfield.vue'
import Card from '@/components/Card.vue'

import rest from '../mixins/rest.js'

export default {
  name: 'nsis-Login',
  components: {
    Inputfield,
    Card
  },
  mixins: [rest],
  data: function() {
    return {
      valid: false,
      error: false,
      blocked: false,
      login: {
        username: "",
        password: ""
      }
    }
  },
  methods: {
    sendLogin: function(){
      if(this.$refs.form.validate()) {
        let these = this;
        this.callApiBasicAuth(this.login.username, this.login.password, () => {
          let api = these.getLoginApi();
          api.login((error, data, response) => {
            if (error) {
              console.log(error)
              if (error.status === 403) {
                these.blocked = true;
                these.error = false;
              } else {
                these.error = true;
                these.blocked = false;
              }
            } else {
              let token = response.header.authorization;
              if (token && token !== "" && data && data !== "") {
                these.$store.commit("setPublicKey", data);
                these.$store.commit("setLoginToken", token);
                these.$router.push({ name: "Startseite" });
              } else {
                these.error = true;
                console.log("Token undefinded!");
              }
            }
          });
        });
      }
    }
  }
}
</script>

<style scoped>
  .sis-push-center {
    position: absolute;
    top: 30%;
  }

  .sis-login-header {
    color: var(--white-text-color);
    background-color: var(--primary-color);
    text-align: center;
    padding: 20px !important;
  }

  .sis-login-button {
    width: 100%;
  }
</style>
