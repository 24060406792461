import Vue from 'vue'
import Vuex from 'vuex'
import * as jose from 'jose'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    boatData: undefined,
    aircraftData: undefined,
    token: sessionStorage.getItem('token'),
    key: sessionStorage.getItem('key'),
  },
  actions: {
    async isLoggedIn(context) {
      const token = context.getters.getLoginToken
      const key = context.getters.getPublicKey
      const algorithm = "RS256"
      if (token && token !== "" && key && key !== "") {
        try {
          const spkiKey = await jose.importSPKI(key, algorithm)
          try {
            await jose.jwtVerify(token, spkiKey)
            return true
          } catch (e) {
            return false
          }
        } catch (error) {
          console.log(error)
          return false
        }
      } else {
        return false
      }
    },
    async getUserRole(context) {
      const token = context.getters.getLoginToken
      const key = context.getters.getPublicKey
      const algorithm = "RS256"
      if (token && token !== "" && key && key !== "") {
        try {
          const spkiKey = await jose.importSPKI(key, algorithm)
          const {payload} = await jose.jwtVerify(token, spkiKey)
          return payload.Authorities
        } catch (error) {
          console.log(error)
          return null
        }
      } else {
        return null
      }
    },
    async getUsernameJWT(context) {
      const token = context.getters.getLoginToken
      const key = context.getters.getPublicKey
      const algorithm = "RS256"
      if (token && token !== "" && key && key !== "") {
        try {
          const spkiKey = await jose.importSPKI(key, algorithm)
          const {payload} = await jose.jwtVerify(token, spkiKey)
          return payload.sub
        } catch (error) {
          console.log(error)
          return null
        }
      } else {
        return null
      }
    },
  },

  mutations: {
    setBoatData(state, boatData) {
      state.boatData = boatData
    },
    setAircraftData(state, aircraftData) {
      state.aircraftData = aircraftData
    },
    clearState(state) {
      state.key = undefined
      state.token = undefined
      state.boatData = undefined
      state.aircraftData = undefined

      sessionStorage.removeItem("token")
      sessionStorage.removeItem("key")
    },
    setLoginToken(state, token) {
      sessionStorage.setItem("token", token)
      state.token = token
    },
    setPublicKey(state, key) {
      sessionStorage.setItem("key", key)
      state.key = key
    },
  },
  getters: {
    getBoatData(state) {
      return state.boatData
    },
    getAircraftData(state) {
      return state.aircraftData
    },
    getLoginToken(state) {
      return state.token
    },
    getPublicKey(state) {
      return state.key
    },
  }
})